import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import Logo from '../../images/oxaco-logos/oxaco-logo.svg';

const OxacoMapLogo = () => <img src={Logo} style={{ width: '50px', height: 'auto' }} alt="Oxaco logo" />


class SimpleMap extends Component {
    static defaultProps = {
        center: {
            lat: 51.170129,
            lng: 4.515984
        },
        zoom: 12
    };

    render() {
        return (
            // Important! Always set the container height explicitly
            <div style={{ height: '30vh', zIndex: 3, position: 'relative' }}>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: 'AIzaSyA6DFuO6HH-7kYJSt3msuSc02fb_DtbN0E' }}
                    defaultCenter={this.props.center}
                    defaultZoom={this.props.zoom}
                    options={{
                        panControl: false,
                        mapTypeControl: false,
                    }}
                >
                    <OxacoMapLogo
                        lat={51.170129}
                        lng={4.515984}
                    />
                    <OxacoMapLogo
                        lat={51.172186}
                        lng={4.490213}
                    />
                </GoogleMapReact>
            </div>
        );
    }
}

export default SimpleMap;
