import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import Layout from '../layout/defaultLayout';

import Container from '../components/layout/Container';
import Map from '../components/layout/map';
import Card from '../components/UI/Card';

const StyledContact = styled.div`
  margin: 20px 0;
  ul {
    display: flex;
    list-style-type: none;
    flex-wrap: wrap;
    justify-content: space-between;
    @media (max-width: 576px) {
      justify-content: space-around;
      margin: 0;
    }
    li {
      margin: 20px;
      @media (max-width: 576px) {
        margin: 10px 5px;
      }
    }
    &.locations {
      align-items: center;
      justify-content: space-around;
    }
    &.board {
      justify-content: space-between;
    }
  }
  h2 {
    color: #333;
  }
  h4 {
    color: #464646;
    font-weight: normal;
    font-size: 20px;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  address span {
    display: block;
    margin-top: 10px;
  }
`;

const contact = ({ data }) => (
  <Layout>
    <Container>
      <StyledContact>
        <section id="accom">
          <h2>Accomodaties</h2>
          <ul className="locations">
            {data &&
              data.locations &&
              data.locations.edges.map(location => {
                const {
                  name,
                  straat,
                  nummer,
                  postcode,
                  plaats,
                  id,
                  afbeelding,
                  googleMapsLink,
                } = location.node;
                return (
                  <li key={id}>
                    <a href={googleMapsLink} target="_blank" rel="noopener noreferrer">
                      <Card
                        image={<Img fixed={afbeelding.fixed} />}
                        title={name}
                        body={
                          <address>
                            <span>{`${straat} ${nummer}`} </span>
                            <span>{`${postcode} ${plaats}`}</span>
                          </address>
                        }
                      />
                    </a>
                  </li>
                );
              })}
          </ul>
        </section>
        <section id="contacts">
          {data && data.contacts && (
            <>
              <h2>Bestuur</h2>

              <ul className="contacts">
                {data.contacts.edges
                  .sort(currentcontact => {
                    const { functie } = currentcontact.node;
                    return functie.toLowerCase().includes('voorzitter') ? -1 : 1;
                  })
                  .map(currentcontact => {
                    const { naam, functie, telefoon, email, foto, id } = currentcontact.node;
                    return (
                      <li key={id}>
                        <Card
                          width="150px"
                          image={foto && <Img fixed={foto.fixed} />}
                          title={naam}
                          body={
                            <>
                              <h4>{functie}</h4>
                              {telefoon && (
                                <a href={`tel:${telefoon}`}>
                                  <p>{telefoon}</p>
                                </a>
                              )}
                              {email && (
                                <a href={`mailto:${email}`} target="_top">
                                  <p>{email}</p>
                                </a>
                              )}
                            </>
                          }
                        />
                      </li>
                    );
                  })}
              </ul>
            </>
          )}
          {data && data.coaches && (
            <>
              <h2>Coaches</h2>
              <ul className="contacts">
                {data.coaches.edges
                  .filter(coach => coach.node.email || coach.node.telefoon)
                  .map(coach => {
                    const { foto, naam, telefoon, email, id, ploeg } = coach.node;

                    return (
                      <li key={id}>
                        <Card
                          width="150px"
                          image={foto && <Img fixed={foto.fixed} />}
                          title={naam.split('-')[0]}
                          body={
                            <>
                              {ploeg && ploeg.map(plg => <p>{plg.naam}</p>)}
                              {telefoon && (
                                <a href={`tel:${telefoon}`}>
                                  <p>{telefoon}</p>
                                </a>
                              )}
                              {email && (
                                <a href={`mailto:${email}`} target="_top">
                                  <p>{email}</p>
                                </a>
                              )}
                            </>
                          }
                        />
                      </li>
                    );
                  })}
              </ul>
            </>
          )}
        </section>
      </StyledContact>
    </Container>
    <Map />
  </Layout>
);

export default contact;

export const contactQuery = graphql`
  query {
    # clubdetail: clubDetail {
    #   details {
    #     guid
    #     stamNr
    #     naam
    #     email
    #     bestuur {
    #       kenmerk
    #       naam
    #       telefoon
    #       mobiel
    #       email
    #     }
    #   }
    # }
    locations: allContentfulLocation {
      edges {
        node {
          id
          name
          straat
          nummer
          postcode
          plaats
          googleMapsLink
          afbeelding {
            fixed(width: 290, height: 200) {
              ...GatsbyContentfulFixed
            }
          }
        }
      }
    }
    contacts: allContentfulContactpersoon {
      edges {
        node {
          foto {
            fixed(width: 150, height: 150) {
              ...GatsbyContentfulFixed
            }
            id
          }
          telefoon
          email
          naam
          functie
          id
        }
      }
    }
    coaches: allContentfulCoach {
      edges {
        node {
          naam
          email
          telefoon
          id
          ploeg {
            naam
          }
        }
      }
    }
  }
`;
